import styled, { css } from 'styled-components';

export const Box = styled.div<{ $isInCard?: boolean }>`
  position: absolute;
  bottom: 10px;
  z-index: 1;
  display: flex;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.palette.system.white};
  ${({ $isInCard }) =>
    $isInCard
      ? css`
          right: 10px;
        `
      : css`
          left: 15px;
        `};
`;

import styled from 'styled-components';

export const Divider = styled.div<{ $isSmall?: boolean; $marginTop?: string }>`
  margin: ${({ $isSmall, $marginTop }) => {
    const finalMT = $marginTop ? $marginTop : $isSmall ? '15px' : '25px';
    return $isSmall ? `${finalMT} auto 15px` : `${finalMT} auto 25px`;
  }};
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.primary.border};
`;

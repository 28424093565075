import React, { PropsWithChildren } from 'react';
import { Overlay } from '@/components/UI/Overlay/Overlay.styles';
import { IconBox } from '@/components/UI/IconBox/IconBox.styles';
import * as S from '@/components/UI/Popup/Popup.styles';
import { PopupProps } from '@/components/UI/Popup/Popup.types';
import { useBodyOverflow } from '@/hooks/useBodyOverflow';
import { Portal } from '@/components/UI/Portal/Portal';
import { CommonTestIds } from '@/__e2e__/testIds/common';

export const Popup: React.FC<PropsWithChildren<PopupProps>> = ({
  isOpen,
  close,
  icon,
  title,
  description,
  actions,
  isInPortal,
  dataTestId = 'popup-box',
  children,
  size = 'medium',
  overlayOpacity,
  iconSize = 25,
  capitalizeTitle,
}) => {
  useBodyOverflow(isOpen);

  function renderPopup() {
    return (
      <>
        <Overlay $isOpen={isOpen} onClick={close} $opacity={overlayOpacity} />
        <S.PopupBox $isOpen={isOpen} $size={size} data-testid={dataTestId}>
          {children || (
            <>
              {!!icon && (
                <IconBox
                  className="popup__icon"
                  $filename={icon}
                  width={iconSize}
                  height={iconSize}
                  $iconWidth={iconSize}
                  $iconHeight={iconSize}
                />
              )}

              {!!title && (
                <S.PopupTitle
                  data-testid={CommonTestIds.popup.title}
                  $capitalizeTitle={capitalizeTitle}
                >
                  {title}
                </S.PopupTitle>
              )}

              {description &&
                (typeof description === 'string' ? (
                  <S.PopupDescription
                    $variant="3"
                    data-testid="popup-description"
                  >
                    {description}
                  </S.PopupDescription>
                ) : (
                  <S.PopupDescriptionBox
                    data-testid={CommonTestIds.popup.description}
                  >
                    {description}
                  </S.PopupDescriptionBox>
                ))}

              {(actions?.left || actions?.right) && (
                <S.PopupActions>
                  {actions?.left && (
                    <S.PopupAction
                      $hasPadding
                      $appearance="transparent"
                      $isRed={actions?.left?.isRed}
                      $isGray={actions?.left?.isGray}
                      onClick={actions?.left?.onClick}
                      data-testid={CommonTestIds.popup.actionLeft}
                      disabled={actions?.left?.disabled}
                    >
                      {actions?.left.text}
                    </S.PopupAction>
                  )}
                  {actions?.right && (
                    <S.PopupAction
                      $hasPadding
                      $appearance="transparent"
                      $isRed={actions?.right?.isRed}
                      $isGray={actions?.right?.isGray}
                      onClick={actions?.right?.onClick}
                      data-testid={CommonTestIds.popup.actionRight}
                      disabled={actions?.right?.disabled}
                    >
                      {actions?.right.text}
                    </S.PopupAction>
                  )}
                </S.PopupActions>
              )}
            </>
          )}
        </S.PopupBox>
      </>
    );
  }

  if (isInPortal) {
    return <Portal>{renderPopup()}</Portal>;
  }

  return renderPopup();
};

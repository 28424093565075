import { AdFlag } from '@/api/types/common.types';
import { IconBox } from '@/components/UI/IconBox/IconBox.styles';
import React from 'react';
import * as S from '@/components/FlagIcons/FlagIcons.styles';
import { FLAG_ICON_NAMES } from '@/components/FlagIcons/FlagIcons.constants';

type Props = {
  flags?: AdFlag[];
  isInCard?: boolean;
};

export const FlagIcons = ({ flags, isInCard }: Props) => {
  return (
    <S.Box $isInCard={isInCard}>
      {flags?.map(flag => {
        const icon = FLAG_ICON_NAMES[flag];

        if (!icon) return null;

        return (
          <IconBox
            key={flag}
            $filename={`${icon}.svg`}
            $iconWidth={isInCard ? 16 : 15}
            $iconHeight={13}
            data-testid="ServiceIcon"
          />
        );
      })}
    </S.Box>
  );
};

import styled from 'styled-components';
import { Typography } from '@/components/UI/Typography/Typography.styles';

export const FooterBox = styled.footer<{ $bottomGutter: string }>`
  padding: ${({ theme, $bottomGutter }) => {
    if (theme.isWebView) {
      return `25px 15px calc(env(safe-area-inset-bottom) + 25px + ${$bottomGutter})`;
    }

    return `25px 15px ${$bottomGutter}`;
  }};
  margin: 0 auto;
  border-top: 1px solid ${({ theme }) => theme.palette.primary.border};
`;

export const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  > a {
    max-width: max-content;
  }
`;

export const FooterSocials = styled.div`
  display: flex;
  gap: 25px;
  margin-top: 25px;
`;

export const FooterSocial = styled.a<{ $iconName: string }>`
  display: block;
  background-image: ${({ $iconName }) => `url('/images/${$iconName}.svg'`});
  height: 37px;
  width: 37px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const FooterSiteVersions = styled.div`
  display: flex;
  align-items: baseline;
  gap: 15px;
`;

export const FooterPhones = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 8px;
`;

export const FooterResponsibility = styled(Typography.Caption)`
  max-width: 85%;
  margin: 15px 0;
`;

import React, { Fragment, useState } from 'react';
import { AdCard } from '@/components/AdCard/AdCard';
import * as S from '@/components/AdCards/AdCards.styles';
import { generateRandomNumber, getDataStatAttr } from '@/utils/helpers';
import { Popup } from '@/components/UI/Popup/Popup';
import { useToggle } from '@/hooks/useToggle';
import { useTranslation } from 'react-i18next';
import { AdManagerBanner } from '@/components/Banners/AdManagerBanner';
import { BannerName } from '@/components/Banners/Banners.types';
import { useWindowSize } from 'usehooks-ts';
import { getCardsCountInRow } from '@/components/AdCards/AdCards.helpers';
import { DEFAULT_ROWS_COUNT_BEFORE_BANNER } from '@/components/AdCards/AdCards.constants';
import { useAdManagerBannerVisible } from '@/components/Banners/AdManagerBanner.hooks';
import { Ad } from '@/api/types/common.types';

interface AdCardsProps {
  ads?: Ad[];
  listDataStat?: string;
  hasActions?: boolean;
  hasFavorite?: boolean;
  hasAdManagerCard?: boolean;
  bannerInList?: (arCn: number) => React.ReactNode;
  isInitialLoading?: boolean;
  isLoadingMore?: boolean;
  isBannerInListAtTheEnd?: boolean;
}

export const AdCards: React.FC<AdCardsProps> = ({
  ads,
  listDataStat,
  hasActions,
  hasFavorite,
  hasAdManagerCard,
  isInitialLoading,
  isLoadingMore,
  bannerInList,
  isBannerInListAtTheEnd,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [isPopupOpen, popupToggle] = useToggle();
  const [selectedAdId, setSelectedAdId] = useState('');
  const isAdManagerBannerVisible = useAdManagerBannerVisible();

  function onTriggerRemove(id: Ad['id']) {
    setSelectedAdId(id);
    popupToggle();
  }

  function remove() {
    console.log('Ad to remove:', selectedAdId);
    setSelectedAdId('');
    popupToggle();
  }

  function isBannerRenders(idx: number): boolean {
    const cardsCountInRow = getCardsCountInRow(width);

    const rowsBeforeBanner =
      isBannerInListAtTheEnd && ads?.length
        ? ads.length / cardsCountInRow
        : DEFAULT_ROWS_COUNT_BEFORE_BANNER;

    const adjustedIndex = isBannerInListAtTheEnd ? idx + 1 : idx;

    const isBannerPosition =
      adjustedIndex % (rowsBeforeBanner * cardsCountInRow) === 0;

    return idx !== 0 && isBannerPosition && !!bannerInList;
  }

  if (isInitialLoading) {
    return (
      <S.Box>
        <AdCard isLoading />
        <AdCard isLoading />
        <AdCard isLoading />
        <AdCard isLoading />
      </S.Box>
    );
  }

  return (
    <>
      <S.Box {...getDataStatAttr({ dataStat: listDataStat })}>
        {ads?.map((ad, idx) => {
          if (hasAdManagerCard && isAdManagerBannerVisible && idx === 3) {
            return <AdManagerBanner key={ad.id} bannerName={BannerName.Card} />;
          }

          if (isBannerRenders(idx)) {
            const banner = (
              <S.BannerBox>
                {bannerInList?.(generateRandomNumber())}
              </S.BannerBox>
            );
            const adCard = (
              <AdCard
                ad={ad}
                hasActions={hasActions}
                hasFavorite={hasFavorite}
                onTriggerRemove={onTriggerRemove}
              />
            );

            return (
              <Fragment key={ad.id}>
                {isBannerInListAtTheEnd ? adCard : banner}
                {isBannerInListAtTheEnd ? banner : adCard}
              </Fragment>
            );
          }

          return (
            <AdCard
              key={ad.id}
              ad={ad}
              hasActions={hasActions}
              hasFavorite={hasFavorite}
              onTriggerRemove={onTriggerRemove}
            />
          );
        })}

        {isLoadingMore && <AdCard isLoading />}
      </S.Box>

      <Popup
        isOpen={isPopupOpen}
        close={popupToggle}
        title={t('pages.cabinet.remove_ad_question')}
        description={t('pages.cabinet.remove_ad_popup_text')}
        isInPortal
        actions={{
          left: { text: t('common.decline'), onClick: popupToggle },
          right: { text: t('common.remove'), isRed: true, onClick: remove },
        }}
      />
    </>
  );
};

import React from 'react';
import * as S from '@/components/AdCard/AdCard.styles';
import { Typography } from '@/components/UI/Typography/Typography.styles';
import { useTranslation } from 'react-i18next';
import { HeartActiveIcon, HeartIcon } from '@/components/UI/Icons/Icons';
import { formatNumber } from '@/utils/helpers';
import { SkeletonLoader } from '@/components/SkeletonLoader/SkeletonLoader';
import { getMessageTime } from '@/utils/datetime.utils';
import { useRouter } from 'next/router';
import { ROUTES } from '@/constants/routes';
import NoSsr from '@/components/NoSsr/NoSsr';
import { useSaveScrollPositionActions } from '@/components/ScrollSaver/ScrollSaver.hooks';
import { CustomLink } from '@/components/CustomLink/CustomLink';
import { useCustomPush } from '@/components/CustomLink/CustomLink.hooks';
import { Ad } from '@/api/types/common.types';
import { FlagIcons } from '@/components/FlagIcons/FlagIcons';
import { useToggleBookmark } from '@/hooks/bookmarks/useToggleBookmark';

interface AdCardProps {
  ad?: Ad;
  isLoading?: boolean;
  className?: string;
  hasActions?: boolean;
  hasFavorite?: boolean;
  onTriggerRemove?: (id: Ad['id']) => void;
  bookmarkIds?: Set<string>;
}

export const AdCard: React.FC<AdCardProps> = ({
  ad,
  isLoading,
  className,
  hasActions = false,
  hasFavorite = true,
  onTriggerRemove,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const customPush = useCustomPush();
  const saveScrollPositionActions = useSaveScrollPositionActions();
  const { isFavorite, toggleBookmark } = useToggleBookmark({ ad });

  function onTriggerRemoveClick(e: React.MouseEvent, id: Ad['id']) {
    e.preventDefault();
    onTriggerRemove?.(id);
  }

  function onEditClick(e: React.MouseEvent) {
    e.preventDefault();
    if (!ad) return;

    customPush(`${ad.path}/ad_edits`);
  }

  if (!ad || isLoading) {
    return (
      <S.Box
        $hasActions={hasActions}
        className={className}
        data-testid="ad-card-skeleton-loader"
      >
        <SkeletonLoader borderRadius={0} width="100%" height="130px" />
        <S.TextsBox>
          <SkeletonLoader width="74px" />
          <S.SkeletonTitle />
          <SkeletonLoader width="113px" />
        </S.TextsBox>
      </S.Box>
    );
  }

  const isBookmarksPage = router.pathname === ROUTES.bookmarks;

  return (
    <S.Box
      dataTestId="ad-card"
      data-stat={`ad-${isBookmarksPage ? 'bookmark-' : ''}card-link`}
      as={CustomLink}
      href={ad.path}
      className={className}
      $hasActions={hasActions}
      onClick={() => saveScrollPositionActions()}
    >
      <S.ImageBox>
        <img
          data-testid="ad-card-image"
          src={ad.photo?.url}
          alt={ad.title}
          width={380}
          height={130}
        />
        {hasFavorite && (
          <S.IsFavorite
            data-testid="bookmark-button"
            appearance="transparent"
            hasPadding={false}
            onClick={toggleBookmark}
          >
            {isFavorite ? (
              <HeartActiveIcon data-testid="is-bookmarked" />
            ) : (
              <HeartIcon data-testid="is-not-bookmarked" />
            )}
          </S.IsFavorite>
        )}

        {ad.shop && <S.Label>{t('common.shop')}</S.Label>}

        <FlagIcons flags={ad.flags} isInCard />
      </S.ImageBox>
      <S.TextsBox>
        {ad.price !== null && (
          <Typography.Numbers $variant="2" data-testid="ad-card-price">
            {formatNumber(ad.price)} AZN
          </Typography.Numbers>
        )}

        <S.Title
          $variant="2"
          $hasActions={hasActions}
          data-testid="ad-card-title"
        >
          {ad.title}
        </S.Title>

        <Typography.Caption data-testid="ad-card-additional" $isEllipsis>
          {ad.region},{' '}
          <NoSsr>{getMessageTime(new Date(ad.updatedAt), t, true)}</NoSsr>
        </Typography.Caption>

        {hasActions && (
          <>
            <S.Action $isLink onClick={onEditClick}>
              {t('common.edit')}
            </S.Action>
            <Typography.Caption
              $isLink
              onClick={e => onTriggerRemoveClick(e, ad.id)}
            >
              {t('common.remove')}
            </Typography.Caption>
          </>
        )}
      </S.TextsBox>
    </S.Box>
  );
};
